import React, { Component } from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

// The NotFoundPage component renders a custom error page to respond
// to requests for non-existent pages
class NotFoundPage extends Component {
  state = {
    language: 'en',
  }

  componentDidMount() { // load language if stored in a previous session
    const storedLanguage = window.localStorage.getItem('markcollisDevLanguage');
    if (storedLanguage) this.setState({ language: storedLanguage });
  }

  setLanguage = (language) => {
    this.setState({ language });
    window.localStorage.setItem('markcollisDevLanguage', language);
  }

  render() {
    const { language } = this.state;
    return (
      <div className="not-found">
        <Layout language={language} setLanguage={this.setLanguage}>
          <SEO title="404: Not found" />
          <section className="section-title">
            <div className="container">
              <h1>
                {(language === 'en')
                  ? 'Page not found'
                  : 'Stránka nenalezena'}
              </h1>
              <h3 className="section-title__profile">
                {(language === 'en')
                  ? 'Sorry, this page doesn\'t exist.'
                  : 'Tato stránka neexistuje.'}
              </h3>
              <Link className="section-link section-link--dark" to="/">
                {(language === 'en')
                  ? 'Home'
                  : 'Domů'}
              </Link>
            </div>
          </section>
        </Layout>
      </div>
    );
  }
}

export default NotFoundPage;
