import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { iconMenu } from '../resources/icons';
import GlideScroll from './GlideScroll';

// The Header component renders a header with navigation bar for the top of each page
class Header extends Component {
  state = {
    navbarPlacement: 'header__navbar-upper', // CSS class: becomes header__navbar-lower when scrolled down
    showMenu: false, // for mobile version / narrow windows (breakpoint is 768px)
  }

  static propTypes = {
    language: PropTypes.string.isRequired,
    setLanguage: PropTypes.func.isRequired,
    siteTitle: PropTypes.string.isRequired,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  toggleMenu = () => {
    const { showMenu: currentShowMenu } = this.state;
    this.setState({ showMenu: !currentShowMenu });
  }

  handleScroll = () => {
    const { navbarPlacement } = this.state;
    const y = window.pageYOffset;
    if ((navbarPlacement === 'header__navbar-upper') && (y > 400)) {
      this.setState({ navbarPlacement: 'header__navbar-lower' });
    }
    if ((navbarPlacement === 'header__navbar-lower') && (y < 400)) {
      this.setState({ navbarPlacement: 'header__navbar-upper' });
    }
  }

  render() {
    const { navbarPlacement, showMenu } = this.state;
    const { language, setLanguage, siteTitle } = this.props;
    const navbarClass = `header__navbar ${navbarPlacement}`;
    const menuClass = `header__navbar-menu${(showMenu) ? '' : ' header__navbar-menu--hide'}`;
    return (
      <header className="header" id="header">
        <nav className={navbarClass}>
          <div className="header__navbar-title">
            <GlideScroll target="header">
              <Link to="/">
                <p>{siteTitle}</p>
                <p className="header__navbar-title--reflected">{siteTitle}</p>
              </Link>
            </GlideScroll>
          </div>
          <button
            type="button"
            className="header__navbar-menu-button"
            onClick={() => this.toggleMenu()}
          >
            Menu
            {iconMenu}
          </button>
          <div className={menuClass}>
            <ul className="header__navbar-list">
              <li className="header__navbar-item">
                <GlideScroll target="about" onClick={() => this.toggleMenu()}>
                  <Link className="header__navbar-link" to="/#about">
                    {(language === 'en')
                      ? 'About'
                      : 'O mňe'}
                  </Link>
                </GlideScroll>
              </li>
              <li className="header__navbar-item">
                <GlideScroll target="projects" onClick={() => this.toggleMenu()}>
                  <Link className="header__navbar-link" to="/#projects">
                    {(language === 'en')
                      ? 'Projects'
                      : 'Projekty'}
                  </Link>
                </GlideScroll>
              </li>
              <li className="header__navbar-item">
                <GlideScroll target="contact" onClick={() => this.toggleMenu()}>
                  <Link className="header__navbar-link" to="/#contact">
                    {(language === 'en')
                      ? 'Contact'
                      : 'Kontakt'}
                  </Link>
                </GlideScroll>
              </li>
              <li className="header__navbar-item">
                <div className="header__navbar-set-language">
                  <button type="button" onClick={() => setLanguage('en')}>
                    <div className="header__navbar-flag header__navbar-flag--en" />
                  </button>
                  <button type="button" onClick={() => setLanguage('cs')}>
                    <div className="header__navbar-flag header__navbar-flag--cs" />
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
