import React from 'react';
import PropTypes from 'prop-types';

// The Footer component renders a footer for the bottom of each page
const Footer = ({ language }) => {
  return (
    <footer className="footer">
      <p>
        &copy;&nbsp;
        {new Date().getFullYear()}
        &nbsp;
        <a href="mailto:mark@markcollis.dev">Mark Collis</a>
      </p>
      <p>
        {(language === 'en')
          ? 'Built with'
          : 'Postaven s'}
        &nbsp;
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </p>
    </footer>
  );
};

Footer.propTypes = {
  language: PropTypes.string.isRequired,
};

export default Footer;
