import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './Header';
import Footer from './Footer';
import '../css/generic.css';
import '../css/main.css';

// The Layout component ensures that each page has a consistent layout
const Layout = ({ children, language, setLanguage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header
        language={language}
        setLanguage={setLanguage}
        siteTitle={data.site.siteMetadata.title}
      />
      <main className="main-content">
        {children}
      </main>
      <Footer
        language={language}
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

export default Layout;
