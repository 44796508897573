import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';

// The GlideScroll component is a wrapper for link components so that the browser
// glides smoothly to the target rather than jumps
class GlideScroll extends Component {
  static propTypes = {
    target: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func, // enables other onClick behaviour if required
  }

  static defaultProps = {
    onClick: () => {},
  }

  componentDidMount() {
    smoothscroll.polyfill();
  }

  handleClick = (e) => {
    e.preventDefault();
    const { target, onClick } = this.props;
    const element = document.getElementById(target);
    if (element) {
      const position = element.getBoundingClientRect().top + window.pageYOffset;
      window.scroll({ top: position, left: 0, behavior: 'smooth' });
    }
    onClick(e);
  }

  render() {
    const { children } = this.props;
    return cloneElement(children, { onClick: this.handleClick });
  }
}

export default GlideScroll;
